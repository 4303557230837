<template>
  <div class="form-group">
    <ValidationProvider :name="name" :rules="rules" :vid="vid" v-slot="{classes,errors}">
        <div class="control" :class="classes">
            <label v-if="label" :for="name">{{label}}</label><!--@input="$emit('input', $event.target.value)"-->
            <input :type="type" :id="id" :name="name" :disabled="disabled" :placeholder="placeholder" :value="value" @input="updateValue($event.target.value)">
            <span>{{ errors[0] }}</span>
        </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
extend('required', {
  ...required,
  message: (field, values) => 'Por favor coloque un dato en el campo ' + `${field}`
});
export default {
  name: 'text-input',
  props: {
    name: String,
    type: String,
    label: String,
    rules: String,
    id: String,
    value: String,
    placeholder: String,
    vid: String,
    disabled: String
  },
  mounted () {
    // this.$el.value = this.value;
  },
  methods: {
    updateValue(value){
        this.$emit("input", value)
    }
  },  
}
</script>